import Api from './api.service'

export default {
  getList (params) {
    return Api().get('/insurance', { params })
  },
  get (id) {
    return Api().get(`/insurance/${id}`)
  },
  store (payload) {
    return Api().post('insurance', payload)
  },
  update (id, payload) {
    return Api().post(`insurance/${id}`, payload)
  },
  delete (id) {
    return Api().delete(`insurance/${id}`)
  }
}
