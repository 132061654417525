<template>
  <div>
    <div class="uk-section uk-section-xsmall uk-padding-remove-top">
      <div data-uk-sticky="offset:52;media: @m" class="uk-background-default breadcrumb" style="padding: 15px 35px; border-bottom: 1px solid rgb(229, 229, 229)">
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div class="uk-flex uk-flex-middle">
            <h1 class="uk-h5 uk-margin-remove uk-flex uk-flex-middle" style="color: rgb(51, 51, 51)">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" class="uk-margin-small-right">
                <path
                  fill-rule="evenodd"
                  d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                  clip-rule="evenodd"
                />
              </svg>
              Versicherungen
            </h1>
          </div>
          <div>
            <router-link :to="{ name: 'insurance-edit' }" class="uk-button uk-button-primary uk-button-small uk-border-rounded">
              <span uk-icon="plus-circle"></span>
              Neue Versicherungen anlegen
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-container uk-margin-top">
      <div class="uk-grid uk-grid-small" data-uk-grid>
        <div class="uk-width-1-1">
          <div class="uk-card uk-card-default uk-margin-large-bottom">
            <div class="uk-card-body">
              <div class="uk-grid uk-grid-small uk-flex uk-flex-middle">
                <div class="uk-width-auto">
                  <!--
                    <search v-slot="{ props, activateFilter }">
                      <div class="uk-width-medium">
                        <input 
                          type="text" 
                          v-model="props.query" 
                          placeholder="Firma, Name, ..." 
                          @keyup.enter.prevent="activateFilter"
                          class="uk-input uk-border-rounded">
                      </div>
                    </search>
                    -->
                </div>
              </div>
              <table class="uk-table uk-table-hover uk-table-striped uk-table-responsive" v-if="insurances.length">
                <thead>
                  <tr>
                    <th class="uk-table-expand">Name</th>
                    <th class="uk-table-expand">Anschrift</th>
                    <th>Telefon</th>
                    <th style="width: 60px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="uk-visible-toggle" v-for="insurance in insurances" :key="insurance.id">
                    <td>
                      <router-link
                        :to="{
                          name: 'insurance-edit',
                          params: { id: insurance.id },
                        }"
                      >
                        {{ insurance.name }}
                      </router-link>
                    </td>
                    <td>
                      {{ insurance.street }}, {{ insurance.zipcode }}
                      {{ insurance.city }}
                    </td>
                    <td>
                      {{ insurance.phone }}
                    </td>
                    <td class="uk-text-right">
                      <router-link
                        class="uk-icon-link uk-margin-small-right uk-text-primary"
                        data-uk-icon="pencil"
                        :to="{
                          name: 'insurance-edit',
                          params: { id: insurance.id },
                        }"
                      >
                      </router-link>
                      <a class="uk-icon-link uk-text-danger" data-uk-icon="trash" href="#" @click.prevent="remove(insurance.id)"> </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="meta && meta.pagination.total_pages > 1">
                <pagination :pagination="meta.pagination"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InsuranceService from '@/services/insurance.service.js'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
import Pagination from '@/components/Pagination'
export default {
  data() {
    return {
      insurances: [],
      checked: [],
      allSelected: false,
      meta: null,
    }
  },
  components: {
    Pagination,
  },
  mounted() {
    this.getInsurances()
  },
  watch: {
    '$route.query': {
      handler() {
        this.getInsurances()
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getInsurances() {
      InsuranceService.getList({ ...this.$route.query }).then((response) => {
        this.insurances = response.data.data
        this.meta = response.data.meta
      })
    },
    remove(id) {
      const $this = this
      this.$uikit.modal
        .confirm('Versicherung löschen?', {
          labels: {
            cancel: 'Abbrechen',
            ok: 'Löschen',
          },
        })
        .then(
          function () {
            InsuranceService.delete(id)
              .then(() => {
                iziToast.success({
                  title: 'OK',
                  message: 'Versicherung gelöscht!',
                  timeout: 3000,
                  position: 'bottomRight',
                  transitionIn: 'fadeInLeft',
                })
                $this.getInsurances()
              })
              .catch(() => {
                iziToast.error({
                  title: 'Fehler',
                  message: 'Versicherung wird in einem Projekt verwendet!',
                  timeout: 3000,
                  position: 'bottomRight',
                  transitionIn: 'fadeInLeft',
                })
              })
          },
          function () {
            console.log('Rejected.')
          }
        )
    },
  },
}
</script>
