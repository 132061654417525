var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"uk-section uk-section-xsmall uk-padding-remove-top"},[_c('div',{staticClass:"uk-background-default breadcrumb",staticStyle:{"padding":"15px 35px","border-bottom":"1px solid rgb(229, 229, 229)"},attrs:{"data-uk-sticky":"offset:52;media: @m"}},[_c('div',{staticClass:"uk-flex uk-flex-between uk-flex-middle"},[_c('div',{staticClass:"uk-flex uk-flex-middle"},[_c('h1',{staticClass:"uk-h5 uk-margin-remove uk-flex uk-flex-middle",staticStyle:{"color":"rgb(51, 51, 51)"}},[_c('svg',{staticClass:"uk-margin-small-right",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z","clip-rule":"evenodd"}})]),_vm._v(" Versicherungen ")])]),_c('div',[_c('router-link',{staticClass:"uk-button uk-button-primary uk-button-small uk-border-rounded",attrs:{"to":{ name: 'insurance-edit' }}},[_c('span',{attrs:{"uk-icon":"plus-circle"}}),_vm._v(" Neue Versicherungen anlegen ")])],1)])])]),_c('div',{staticClass:"uk-container uk-margin-top"},[_c('div',{staticClass:"uk-grid uk-grid-small",attrs:{"data-uk-grid":""}},[_c('div',{staticClass:"uk-width-1-1"},[_c('div',{staticClass:"uk-card uk-card-default uk-margin-large-bottom"},[_c('div',{staticClass:"uk-card-body"},[_vm._m(0),(_vm.insurances.length)?_c('table',{staticClass:"uk-table uk-table-hover uk-table-striped uk-table-responsive"},[_vm._m(1),_c('tbody',_vm._l((_vm.insurances),function(insurance){return _c('tr',{key:insurance.id,staticClass:"uk-visible-toggle"},[_c('td',[_c('router-link',{attrs:{"to":{
                        name: 'insurance-edit',
                        params: { id: insurance.id },
                      }}},[_vm._v(" "+_vm._s(insurance.name)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(insurance.street)+", "+_vm._s(insurance.zipcode)+" "+_vm._s(insurance.city)+" ")]),_c('td',[_vm._v(" "+_vm._s(insurance.phone)+" ")]),_c('td',{staticClass:"uk-text-right"},[_c('router-link',{staticClass:"uk-icon-link uk-margin-small-right uk-text-primary",attrs:{"data-uk-icon":"pencil","to":{
                        name: 'insurance-edit',
                        params: { id: insurance.id },
                      }}}),_c('a',{staticClass:"uk-icon-link uk-text-danger",attrs:{"data-uk-icon":"trash","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove(insurance.id)}}})],1)])}),0)]):_vm._e(),(_vm.meta && _vm.meta.pagination.total_pages > 1)?_c('div',[_c('pagination',{attrs:{"pagination":_vm.meta.pagination}})],1):_vm._e()])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uk-grid uk-grid-small uk-flex uk-flex-middle"},[_c('div',{staticClass:"uk-width-auto"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"uk-table-expand"},[_vm._v("Name")]),_c('th',{staticClass:"uk-table-expand"},[_vm._v("Anschrift")]),_c('th',[_vm._v("Telefon")]),_c('th',{staticStyle:{"width":"60px"}})])])
}]

export { render, staticRenderFns }